import React from 'react'
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Clear from "@material-ui/icons/Clear";
import RichTextEditor from 'react-rte';
import Cookie from "../../../assets/js/utils/Cookie";
import sendicon from "../../../assets/img/sharefolder.png";
import sendiconDisabled from  "../../../assets/img/sendicon-disabled.png";
import { helper } from '../../../assets/js/utils/Element';
import fileicon from "../../../assets/img/fileicon.png";
import foldericon from "../../../assets/img/foldericon.png";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import LoaderComponent from "../Loader";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import versionControlIconNew from "../../../assets/img/version_control.png";
import expiryChangeIcon from "../../../assets/img/expirychange.png";
import notificationIcon from "../../../assets/img/notification.png";
import clearUserIcon from "../../../assets/img/clearuser.png";
import ReactTable from "react-table";
import ChangeExpiryDateModal from "./ChangeExpiryDateModal";
import ChangeVersionControlModal from "./ChangeVersionControlModal";
import ChangeNotificationModal from "./ChangeNotificationModal";
import ConfirmationModal from "./ConfirmationModal";
import moment from 'moment';
import MaterialIcon from "@mdi/react";
import { mdiPencil, mdiEyeOutline, mdiAccountGroup } from '@mdi/js';
import UserRecentActivity from './UserRecentActivity';
import CsvFileIcon from "../../../assets/img/csv-file-icon.png";
import ShareUserStatusModal from "./ShareUserStatusModal";

import shareModalStyle from "../../../assets/jss/user/shareModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ShareModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { sharedWith } = this.props;
        let permissionMode = false;
        if(sharedWith !== null && sharedWith.length > 0){
            permissionMode = true;
        }

        let yourEmail = Cookie.read("sendlinx_your_email");
        if(!yourEmail || yourEmail === "null"){
            yourEmail = "";
        }
        const { authorized, user } = this.store.getState();
        if(authorized){
            yourEmail = user.email;
        }
        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        this.state = {
            emailTo: "",
            recipients: [],
            yourEmail: yourEmail,
            transferType: "email",
            message: RichTextEditor.createEmptyValue(),
            validation: {
                emailTo: "",
                yourEmail: ""                
            },
            showError: false,
            errorMessage: "",
            response: null,
            checked: this.getCheckedFiles(),
            path: decodeURIComponent(path.trim()),
            downloadUrl: "",
            fileId: this.props.fileId,
            saving: false,
            permissionMode: permissionMode,
            sharedWith: sharedWith,
            removedNotice: false,
            checkedUsers: [],
            expire: null,
            expireNewUser: null,
            changeExpiryDateModal: false,
            changingExpiryDate: false,
            versionControl: "0",
            groupNotification: "0",
            ownerNotification: "0",
            setExpiryDateModal: false,
            userId: null,
            clearUsersModal: false,
            clearingUsers: false,
            changeBulkExpiryDateModal: false,
            changeVersionControlModal: false,
            checkedFileIds: this.getCheckedFileIds(),
            changeBulkOwnerNotiModal: false,
            changeBulkGroupNotiModal: false,
            shareUserStatus: '',
            shareUserId: null,
            shareUserStatusModal: false,
            signupEmailModal: false,
            permissionUser: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleTransfer = this.toggleTransfer.bind(this);
        this.onEmailKeyUp = this.onEmailKeyUp.bind(this);
        this.sendFiles = this.sendFiles.bind(this);

        this.privacyOptions = [
            {
                value: "Read",
                key: "read"
            },
            {
                value: "Write",
                key: "write"
            },
            {
                value: "Remove",
                key: "remove"
            }
        ];

        this.versionControlOptions = [
            {
                value: "Not Set",
                key: "0"
            },
            {
                value: "Date/Time",
                key: "datetime"
            },
            {
                value: "Alphanumeric",
                key: "alphanumeric"
            }
        ];

        this.notificationOptions = [
            {
                value: "Disabled",
                key: "0"
            },
            {
                value: "Optional",
                key: "optional"
            },
            {
                value: "Mandatory",
                key: "mandatory"
            }
        ]
    }
    getCheckedFiles(){
        const { checked, files } = this.props;

        let checkedArray = [];
        checked.map(fileId => {
            let file = files.filter(x => x.id === fileId)[0];
            let arrayObject = {
                id: fileId,
                privacy: "read",
                versionControl: file.versionControl === 1 ? true : false
            }

            checkedArray.push(arrayObject);
            return null;
        });

        return checkedArray;
    }
    onEmailKeyUp(event) {
        if(event && event.nativeEvent.keyCode !== 13){
            return;
        }
        const { emailTo } = this.state;
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.emailTo.length < 0 || !emailRegex.test(emailTo)){
            return;
        }
        let commaSeparatedEmails = emailTo.split(/[;,]\s*/);
        let recipients = this.state.recipients;
        let duplicate = false;

        recipients.map(email => {
            var duplicateValue = commaSeparatedEmails.filter(value => recipients.includes(value));

            if(email === emailTo || duplicateValue.length > 0){
                duplicate = true;
            }
            return null;
        });
        if(duplicate){
            this.setState({
                recipients: recipients,
                emailTo: ""
            });
            return;
        }
        if(commaSeparatedEmails.length > 1){
            commaSeparatedEmails.map((email) => {
                if(emailRegex.test(email.trim())){
                    recipients.push(email.trim());
                }
                return null;
            });
        }else{
            recipients.push(emailTo);
        }

        this.setState({
            recipients: recipients,
            emailTo: "",
            permissionMode: false
        });
        
    } 
    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    }
    handleMessageChange(content, name){
        this.setState({
            [name]: content
        });
    }
    removeRecipient(e, email){
        e.preventDefault();
        let recipients = this.state.recipients;
        if(recipients.length <= 0 || email.length <= 0){
            return;
        }
        const index = recipients.indexOf(email);
        recipients.splice(index, 1);
        let state = {recipients: recipients};
        const { sharedWith } = this.state;
        if(sharedWith !== null && sharedWith.length > 0 && recipients.length <= 0){
            state['permissionMode'] = true;
        }
        this.setState(state);
    }
    editRecipient(e, email){
        e.preventDefault();
        let recipients = this.state.recipients;
        if(recipients.length <= 0 || email.length <= 0){
            return;
        }
        const index = recipients.indexOf(email);
        recipients.splice(index, 1);
        this.setState({
            recipients: recipients,
            emailTo: email
        });
    }
    toggleTransfer(event, type){
        this.setState({
            transferType: type
        });
    }
    getFilesCount(){
        const { files } = this.props;

        let totalFiles = 0;
        files.map(item => {
            totalFiles++;
            return null;
        });
        return totalFiles;
    }
    getRemainingSize(){
        const { files } = this.props;

        let totalSize = 0;
        let maxSize = 2147483648;
        const { authorized, user } = this.store.getState();
        if(authorized){
            maxSize = user.quota - user.usage;
        }
        files.map(item => {
            totalSize += helper.getBytesSize(item.size);
            return null;
        });
        return maxSize - totalSize;
    }
    sendFiles(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const { emailTo, path, checked, fileId, versionControl, ownerNotification, groupNotification, expireNewUser } = this.state;
        let recipients = this.state.recipients;
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.emailTo.length > 0 && emailRegex.test(emailTo)){
            let duplicate = false;
            recipients.map(email => {
                if(email === emailTo){
                    duplicate = true;
                }
                return null;
            });
            if(!duplicate){
                recipients.push(emailTo);
            }
        }

        Cookie.write("sendlinx_your_email", this.state.yourEmail);
        let message = this.state.message.toString("html");
        
        const requestData = {
            recipients: recipients,
            from: this.state.yourEmail,
            message: message,
            transferType: this.state.transferType,
            uploadType: "web",
            files: checked,
            path: path,
            zipUpload: 0,
            shareFolder: true
        };

        const source = axios.CancelToken.source();
        this.setState({
            recipients: recipients,
            emailTo: "",
            cancelToken: source,
            showError: false,
            saving: true
        });

        if(typeof(path) === "string" && path.indexOf("/Web/My Docs") !== -1){
            requestData['uploadType'] = "my_docs";
        }
        if(fileId !== null){
            requestData['sharedFileId'] = fileId;
        }else if(checked.length > 0 && this.props.sharedPage){
            requestData['sharedFileId'] = checked[0].id;
        }
        if(versionControl !== null){
            requestData['versionControl'] = versionControl;
        }
        if(ownerNotification !== null){
            requestData['ownerNotification'] = ownerNotification;
        }
        if(groupNotification !== null){
            requestData['groupNotification'] = groupNotification;
        }
        if(expireNewUser !== null && expireNewUser.length > 0){
            requestData['expireAt'] = expireNewUser;
        }
        Api.shareFiles(requestData, source).then(data => {
            this.setState({downloadUrl: data.downloadUrl});

            this.props.onClose();
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, errorMessage: err.message, showError: true, saving: false});
            }
        });
    }
    validateForm(){
        let validation = {
            emailTo: 'success',
            yourEmail: 'success',
            isValid: true
        };

        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.transferType === "email"){
            if((this.state.emailTo.length <= 0 || !emailRegex.test(this.state.emailTo)) && this.state.recipients.length <= 0){
                validation.emailTo = "error";
                validation.isValid = false;
            }
        }
        if(this.state.yourEmail.length <= 0 || !emailRegex.test(this.state.yourEmail)){
            validation.yourEmail = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    handleVersionControl(item, type){
        let itemId = item.id;
        let checkedObj = this.state.checked.filter(x => x.id === itemId)[0];
        if(checkedObj.versionControl === type){
            checkedObj.versionControl = "";
        }else{
            checkedObj.versionControl = type;
        }

        this.setState({
            checked: [
                ...this.state.checked
            ]
        })
    }
    handleToggle(item, type){
        let itemId = item.id;
        let checkedObj = this.state.checked.filter(x => x.id === itemId)[0];
        checkedObj.privacy = type;
        
        this.setState({
            checked: [
                ...this.state.checked,
            ]
        })
    }
    renderFile(item, key){      
        const { classes } = this.props;

        let itemId = item.id;
        let checkedObj = this.state.checked.filter(x => x.id === itemId)[0];

        //checkedObj.versionControl = (item.versionControl === 1 || item.versionControl === true) ? true : false;
        
        return <li key={key}>
            <div className="file">
                <div className="file-header">
                    <div className="file-title">
                        <span className="file-permissions">
                            <span className="read-write">
                                <Checkbox
                                    tabIndex={-1}
                                    checked={checkedObj.privacy === "write"}
                                    onClick={() => this.handleToggle(item, 'write')}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                            </span>
                            <span className="view">
                                <Checkbox
                                    tabIndex={-1}
                                    checked={checkedObj.privacy === "read"}
                                    onClick={() => this.handleToggle(item, 'read')}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot
                                    }}
                                />
                            </span>
                        </span>
                        <span className={(item.type === "file" ? "file-icon" : "folder-icon")+" icon"}><img src={(item.type === "file" ? fileicon : foldericon)} alt={"file"} /></span>
                        <span className="file-name" title={item.name}>{item.name} | <span><b>Folder Path:</b> {item.folder_path}</span></span>
                        <span className="file-detail">{item.size}</span>
                    </div>
                </div>
            </div>
        </li>;
    }
    getConfig(){
        const toolbarConfig = {
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
              {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
              {label: 'Italic', style: 'ITALIC'},
              {label: 'Underline', style: 'UNDERLINE'}
            ],
            BLOCK_TYPE_DROPDOWN: [
              {label: 'Normal', style: 'unstyled'},
              {label: 'Heading Large', style: 'header-one'},
              {label: 'Heading Medium', style: 'header-two'},
              {label: 'Heading Small', style: 'header-three'}
            ],
            BLOCK_TYPE_BUTTONS: [
              {label: 'UL', style: 'unordered-list-item'},
              {label: 'OL', style: 'ordered-list-item'}
            ]
        };
        return toolbarConfig;
    }
    handlePrivacyChange(e, email){
        const { sharedWith } = this.state;
        const { checked } = this.props;
        let removedNotice = false;
        let permissionUser = null;
        sharedWith.map((user, key) => {
            if(user.email === email){
                const newPermission = e.target.value;
                const oldPermission = sharedWith[key]['privacy'];
                sharedWith[key]['privacy'] = newPermission;
                if(newPermission === "remove"){
                    sharedWith.splice(key, 1);
                    removedNotice = true;
                }
                if(oldPermission === "read" && newPermission === "write" && !user.signup && !user.signupNotification){
                    permissionUser = sharedWith[key];
                }
            }
            return null;
        });
        this.setState({
            sharedWith: sharedWith,
            removedNotice: removedNotice,
            signupEmailModal: (permissionUser !== null),
            permissionUser: permissionUser
        });

        const requestData = {
            id: checked[0]
        };
        requestData['permissions'] = sharedWith;
        const source = axios.CancelToken.source();
        Api.sharePermissions(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, errorMessage: err.message});
            }
        });
    }
    handleSelectControlChange(e, email, name){
        const { sharedWith } = this.state;
        const { checked } = this.props;
        sharedWith.map((user, key) => {
            if(user.email === email){
                sharedWith[key][name] = e.target.value;
            }
            return null;
        });
        this.setState({sharedWith: sharedWith});

        const requestData = {
            id: checked[0]
        };
        requestData['permissions'] = sharedWith;
        const source = axios.CancelToken.source();
        Api.sharePermissions(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, errorMessage: err.message});
            }
        });
    }
    onSelectControlChange(e, name){
        this.setState({
            [name]: e.target.value
        });
    }
    onNoticeClose(){
        this.setState({removedNotice: false});
    }
    getColumns(){
        const { classes } = this.props;
        let columns = [
            {
                Header: (
                    <Checkbox
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        onChange={(e) => this.onToggleAllUsers(e)}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false,
            },
            {
                Header: "Users",
                accessor: "email",
                headerClassName: "hd_email",
                className: "hd_email td_email",
            },
            {
                Header: "Notify Owner",
                accessor: "ownerNotification",
                headerClassName: "hd_owner_notification",
                className: "hd_owner_notification td_owner_notification",
            },
            {
                Header: "Notify Group",
                accessor: "groupNotification",
                headerClassName: "hd_group_notification",
                className: "hd_group_notification td_group_notification",
            },
            {
                Header: "Expiry Date / Time",
                accessor: "expires",
                headerClassName: "hd_expires",
                className: "hd_expires td_expires",
            },
            {
                Header: "Status",
                accessor: "status",
                headerClassName: "hd_status",
                className: "hd_status td_status",
            },
            {
                Header: "Version Control",
                accessor: "versionControl",
                headerClassName: "hd_versionControl",
                className: "hd_versionControl td_versionControl",
            },
            {
                Header: "Permissions",
                accessor: "permissions",
                headerClassName: "hd_permissions",
                className: "hd_permissions td_permissions",
            },
        ];
        return columns;
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                // onClick: (e) => this.handleUserToggle(rowInfo.original),
            }
        }else{
            return {}
        }
    }
    handleUserToggle(user) {
        if(this.loadingFolder){
            return;
        }
        const { checkedUsers } = this.state;
        const currentIndex = checkedUsers.indexOf(user.user_id);
        const newChecked = [...checkedUsers];
    
        if (currentIndex === -1) {
            newChecked.push(user.user_id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checkedUsers: newChecked,
        });
    }
    getFormatedExpiry(expire_at){
        if(!expire_at || expire_at.length <= 0){
            return "No Expiry Date";
        }
        var expire = new Date(expire_at);
        return moment(expire).format("MM/DD/YYYY h:mm A");
    }
    onChangeExpiryDate(status = false, userId = null, expire = null){
        const { checked } = this.state;
        if(checked.length <= 0 && userId === null && status === true){
            return;
        }
        this.setState({
            changeExpiryDateModal: status,
            userId: userId,
            expire: expire
        });
    }
    onChangeExpiryDateSuccess(expire){
        const source = axios.CancelToken.source();
        const parsedDate = new Date(expire);

        if(expire !== '' && parsedDate !== "Invalid Date"){
            const day = parsedDate.getDate();
            const month = parsedDate.getMonth() + 1;
            const year = parsedDate.getFullYear();
            const hours = parsedDate.getHours();
            const mins = parsedDate.getMinutes();
            const secs = parsedDate.getSeconds();
            expire = year+"-"+month+"-"+day+" "+hours+":"+mins+":"+secs;
        }

        const { sharedWith, userId } = this.state;
        const { checked } = this.props;
        sharedWith.map((user, key) => {
            if(user.user_id === userId){
                sharedWith[key]['expireAt'] = expire;
            }
            return null;
        });
        this.setState({
            cancelToken: source,
            sharedWith: sharedWith,
            userId: null,
            changeExpiryDateModal: false
        });

        const requestData = {
            id: checked[0]
        };
        requestData['permissions'] = sharedWith;
        Api.sharePermissions(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            console.log(err);
        });
    }
    onSetExpiryDate(status = false){
        this.setState({
            setExpiryDateModal: status,
        });
    }
    onSetExpiryDateSuccess(expire){
        const parsedDate = new Date(expire);

        if(expire !== '' && parsedDate !== "Invalid Date"){
            const day = parsedDate.getDate();
            const month = parsedDate.getMonth() + 1;
            const year = parsedDate.getFullYear();
            const hours = parsedDate.getHours();
            const mins = parsedDate.getMinutes();
            const secs = parsedDate.getSeconds();
            expire = year+"-"+month+"-"+day+" "+hours+":"+mins+":"+secs;
        }
        
        this.setState({
            expireNewUser: expire,
            setExpiryDateModal: false
        });
    }
    onShareUserStatusModal(status = false, userStatus = null, shareUserId = null){
        this.setState({
            shareUserStatus: userStatus,
            shareUserStatusModal: status,
            shareUserId: shareUserId
        })
    }
    onShareUserStatusSuccess(status){
        const source = axios.CancelToken.source();

        const { sharedWith, shareUserId } = this.state;
        const { checked } = this.props;
        
        this.setState({
            cancelToken: source,
            shareUserStatusModal: false,
        });

        const requestData = {
            id: checked[0],
            userId: shareUserId,
            status: status
        };

        Api.sendStatusNotification(requestData, source).then(data => {
            let response = data.response;

            sharedWith.map((user, key) => {
                if(user.user_id === shareUserId){
                    sharedWith[key]['status'] = response.status;
                    sharedWith[key]['expireAt'] = response.expireAt
                }
                return null;
            });

            this.setState({
                sharedWith: sharedWith,
                shareUserId: null
            });
            
        }).catch(err => {
            console.log(err);
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { sharedWith, checkedUsers } = this.state;
        if(sharedWith === null){
            return [];
        }
        let tableData = [];
        sharedWith.map(user => {
            const userArray = {
                user_id: user.user_id,
                check: (
                    user.isOwner === true ?
                        <></>
                    :
                        <Checkbox
                            tabIndex={-1}
                            checked={checkedUsers.includes(user.user_id)}
                            className={classes.positionAbsolute}
                            onClick={() => this.handleUserToggle(user)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                        />
                ),
                email: user.email,
                ownerNotification: (
                    user.isOwner ?
                        "Disabled"
                    :
                        <div className={classes.customSelectControl}>
                            <CustomSelect 
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                labelText="Notify Owner"
                                selectProps={{
                                    onChange: (e) => this.handleSelectControlChange(e, user.email, 'ownerNotification'),
                                    value: (user.ownerNotification.length === 0 ? "0" : user.ownerNotification)
                                }}
                                inputProps={{
                                    name: "ownerNotification",
                                }}
                                color="custom"
                                items={this.notificationOptions}
                                itemLabel="value"
                                itemValue="key"
                            />
                        </div>
                ),
                groupNotification: (
                    <div className={classes.customSelectControl}>
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true,
                            }}
                            labelText="Notify Group"
                            selectProps={{
                                onChange: (e) => this.handleSelectControlChange(e, user.email, 'groupNotification'),
                                value: (user.groupNotification.length === 0 ? "0" : user.groupNotification)
                            }}
                            inputProps={{
                                name: "groupNotification",
                            }}
                            color="custom"
                            items={this.notificationOptions}
                            itemLabel="value"
                            itemValue="key"
                        />
                    </div>
                ),
                expires: (
                    user.isOwner ?
                        "No Expiry Date"
                    :
                        <div>
                            {this.getFormatedExpiry(user.expireAt)}
                            <Button color="transparent" justIcon onClick={() => this.onChangeExpiryDate(true, user.user_id, user.expireAt)}>
                                <MaterialIcon path={mdiPencil} className="MuiSvgIcon-root" />
                            </Button>
                        </div>
                ),
                versionControl: (
                    <div className={classes.customSelectControl}>
                        <CustomSelect 
                            formControlProps={{
                                fullWidth: true,
                            }}
                            labelText="Version Control"
                            selectProps={{
                                onChange: (e) => this.handleSelectControlChange(e, user.email, 'versionControl'),
                                value: (user.versionControl.length === 0 ? "0" : user.versionControl)
                            }}
                            inputProps={{
                                name: "versionControl",
                            }}
                            color="custom"
                            items={this.versionControlOptions}
                            itemLabel="value"
                            itemValue="key"
                        />
                    </div>
                ),
                permissions: (
                    user.isOwner ?
                        <div className={classes.capitalize}>
                            {user.privacy}
                        </div>
                    :
                        <div className={classes.customSelectControl}>
                            <CustomSelect 
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                labelText="Permission"
                                selectProps={{
                                    onChange: (e) => this.handlePrivacyChange(e, user.email),
                                    value: user.privacy
                                }}
                                inputProps={{
                                    name: "privacy",
                                }}
                                color="custom"
                                items={this.privacyOptions}
                                itemLabel="value"
                                itemValue="key"
                            />
                        </div>
                ),
                status: (
                    user.isOwner ?
                        <div>
                            { 
                                user.status === 'viewonly' ?
                                    "View Only"
                                :
                                    user.status
                            }
                        </div>
                    :
                        <div onClick={() => this.onShareUserStatusModal(true, user.status, user.user_id)}>
                            { 
                                user.status === 'viewonly' ?
                                    "View Only"
                                :
                                    user.status
                            }
                        </div>
                ),
                recentActivities: user.recentActivities
            };
            tableData.push(userArray);
            return null;
        });
        return tableData;
    }
    onExportUserActivity(e){
        e.preventDefault();
    }
    onClearUsersModal(status = false){
        const { checkedUsers } = this.state;
        if(checkedUsers.length <= 0 && status === true){
            return;
        }
        this.setState({
            clearUsersModal: status,
        });
    }
    onClearUsersModalSuccess(){
        const { checkedUsers } = this.state;
        const { checked } = this.props;
        const source = axios.CancelToken.source();
        this.setState({
            clearingUsers: true,
            cancelToken: source,
        });
        const requestData = {
            users: checkedUsers,
            id: checked[0]
        };

        const { sharedWith } = this.state;
        const newSharedWith = [];
        sharedWith.map((user, key) => {
            if(!checkedUsers.includes(user.user_id)){
                newSharedWith.push(user);
            }
            return null;
        });

        Api.clearPermissionUsers(requestData, source).then(data => {
            this.setState({
                checkedUsers: [],
                sharedWith: newSharedWith,
                clearUsersModal: false,
                clearingUsers: false,
            });
        }).catch(err => {
            this.setState({
                clearUsersModal: false,
                clearingUsers: false,
            });
        });
    }
    onToggleAllUsers(e){
        const checkedUsers = [];
        if(e.target.checked){
            const { sharedWith } = this.state;
            sharedWith.map((user, key) => {
                checkedUsers.push(user.user_id);
                return null;
            });
        }

        this.setState({
            checkedUsers: checkedUsers
        });
    }
    onBulkNotiChange(status = false, name){
        const { checkedUsers } = this.state;
        if(checkedUsers.length <= 0 && status === true){
            return;
        }
        this.setState({
            [name]: status
        });
    }
    onBulkNotiChangeSuccess(notification, name, modalName){
        const source = axios.CancelToken.source();

        const { sharedWith, checkedUsers } = this.state;
        const { checked } = this.props;
        sharedWith.map((user, key) => {
            if(checkedUsers.includes(user.user_id)){
                sharedWith[key][name] = notification;
            }
            return null;
        });
        this.setState({
            cancelToken: source,
            sharedWith: sharedWith,
            [modalName]: false
        });

        const requestData = {
            id: checked[0]
        };
        requestData['permissions'] = sharedWith;
        Api.sharePermissions(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            console.log(err);
        });
    }
    onBulkExpiryDateChange(status = false){
        const { checkedUsers } = this.state;
        if(checkedUsers.length <= 0 && status === true){
            return;
        }
        this.setState({
            changeBulkExpiryDateModal: status
        });
    }
    onBulkExpiryDateChangeSuccess(expire){
        const source = axios.CancelToken.source();
        const parsedDate = new Date(expire);
        
        if(expire !== '' && parsedDate !== "Invalid Date"){
            const day = parsedDate.getDate();
            const month = parsedDate.getMonth() + 1;
            const year = parsedDate.getFullYear();
            const hours = parsedDate.getHours();
            const mins = parsedDate.getMinutes();
            const secs = parsedDate.getSeconds();
            expire = year+"-"+month+"-"+day+" "+hours+":"+mins+":"+secs;
        }

        const { sharedWith, checkedUsers } = this.state;
        const { checked } = this.props;
        sharedWith.map((user, key) => {
            if(checkedUsers.includes(user.user_id)){
                sharedWith[key]['expireAt'] = expire;
            }
            return null;
        });
        this.setState({
            cancelToken: source,
            sharedWith: sharedWith,
            changeBulkExpiryDateModal: false
        });

        const requestData = {
            id: checked[0]
        };
        requestData['permissions'] = sharedWith;
        Api.sharePermissions(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            console.log(err);
        });
    }
    onBulkVersionControlChange(status = false){
        const { checkedUsers } = this.state;
        if(checkedUsers.length <= 0 && status === true){
            return;
        }
        this.setState({
            changeVersionControlModal: status
        });
    }
    onBulkVersionControlChangeSuccess(versionControl){
        const source = axios.CancelToken.source();

        const { sharedWith, checkedUsers } = this.state;
        const { checked } = this.props;
        sharedWith.map((user, key) => {
            if(checkedUsers.includes(user.user_id)){
                sharedWith[key]['versionControl'] = versionControl;
            }
            return null;
        });
        this.setState({
            cancelToken: source,
            sharedWith: sharedWith,
            changeVersionControlModal: false
        });

        const requestData = {
            id: checked[0]
        };
        requestData['permissions'] = sharedWith;
        Api.sharePermissions(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            console.log(err);
        });
    }
    getCheckedFileIds(){
        let fileIds = [];
        let checked = this.getCheckedFiles();
        checked.map(file => {
            let fileId = file.id;

            fileIds.push(fileId);
            return null;
        })
        
        return fileIds;
    }
    onSignupEmailModalClose(){
        this.setState({
            signupEmailModal: false,
            permissionUser: null
        });
    }
    onSignupEmailModalSuccess(){
        const { permissionUser, sharedWith, checkedFileIds } = this.state;
        sharedWith.map((user, key) => {
            if(user.email === permissionUser.email){
                sharedWith[key]['signupNotification'] = 1;
            }
            return null;
        });
        let fileId = this.state.fileId;
        if(fileId === null && checkedFileIds.length > 0){
            fileId = checkedFileIds[0];
        }

        const source = axios.CancelToken.source();
        const requestData = {
            id: fileId,
            userId: permissionUser.user_id,
            transferId: permissionUser.transferId
        };
        Api.sendSignupInvitation(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            console.log(err);
        });

        this.setState({
            cancelToken: source,
            signupEmailModal: false,
            permissionUser: null,
            sharedWith: sharedWith
        });
    }
    render() {
        const { classes, files } = this.props;
        const { transferType, recipients, validation, message, yourEmail, saving, permissionMode, sharedWith, removedNotice,
        errorMessage, showError, changeExpiryDateModal, changingExpiryDate, expire, versionControl, expireNewUser, setExpiryDateModal,
        clearUsersModal, clearingUsers, changeBulkExpiryDateModal, changeVersionControlModal, checkedUsers, checkedFileIds,
        ownerNotification, groupNotification, changeBulkOwnerNotiModal, changeBulkGroupNotiModal, shareUserStatus, 
        shareUserStatusModal, signupEmailModal, permissionUser } = this.state;
        const { authorized } = this.store.getState();
        const filesCount = this.getFilesCount();
        const remainingSize = this.getRemainingSize();
        const tableData = this.getTableData();
        const apiUrl = Api.getUsersActivityExportUrl('', checkedFileIds);

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="shareModal-modal-slide-title"
                aria-describedby="shareModal-modal-slide-description"
            >
                <DialogContent
                    id="shareModal-modal-slide-description"
                    className={classes.modalBody}
                >
                    {
                        saving === false ?
                            <Button
                                className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                                onClick={() => this.props.onClose()}
                                color="custom"
                                simple
                            >
                                <CloseIcon className={classes.modalClose} />
                            </Button>
                        :
                        <></>
                    }
                    {
                        showError ?
                            <p className={classes.errorMessage}>{errorMessage}</p>
                        :
                        saving ?
                            <LoaderComponent color="custom" align="center" />
                        :
                        <div className={classes.transferForm+" transferForm "+classes.fileSendingForm}>
                            <h3>Share & Collaborate</h3>
                            <div className={classes.transferContent}>
                                <div className={classes.formFields+" uploader-fields"}>
                                    {
                                        transferType === "email" ?
                                            <>
                                                {
                                                    recipients.length > 0 ?
                                                        <div className={"react-tagsinput "+classes.tagsInput}>
                                                            <div className="users-icon">
                                                                <MaterialIcon path={mdiAccountGroup} />
                                                            </div>
                                                            <div className="users-lists">
                                                                {
                                                                    recipients.map((email, key) => {
                                                                        return (
                                                                            <span className="react-tagsinput-tag rose" key={key}>
                                                                                <span onClick={(e) => this.editRecipient(e, email)}>{email}</span>
                                                                                <a href="/" onClick={(e) => this.removeRecipient(e, email)}>&nbsp;</a>
                                                                            </span>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    :
                                                    <></>
                                                }
                                                <div>
                                                    <div className="formElement">
                                                        <input
                                                            type="email" 
                                                            value={this.state.emailTo} 
                                                            placeholder="To: user@example.com"
                                                            id="outlined-emailTo"
                                                            name= "emailTo"
                                                            onChange= {(e) => this.handleChange(e, 'emailTo')}
                                                            onKeyUp= {(e) => this.onEmailKeyUp(e)}
                                                            onBlur= {() => this.onEmailKeyUp(null)}
                                                        >
                                                        </input>
                                                        <p className="recipients-description">Tab or Enter after each email</p>
                                                        {
                                                            validation.emailTo === 'error' ?
                                                                <Clear className="claer-icon" />
                                                            :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>           
                                            </>
                                        :
                                        <></>
                                    }
                                    {
                                        permissionMode && sharedWith !== null && sharedWith.length > 0 ?
                                            <></>
                                        :
                                            <div>
                                                <div className="formElement">
                                                    <input 
                                                        type="email" 
                                                        value={yourEmail} 
                                                        placeholder="From: user@example.com"
                                                        id="outlined-yourEmail"
                                                        name= "yourEmail"
                                                        onChange= {(e) => this.handleChange(e, 'yourEmail')}
                                                        disabled={authorized ? true : false}
                                                    >
                                                    </input>
                                                    {
                                                        validation.yourEmail === 'error' ?
                                                            <Clear className="claer-icon" />
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                    }
                                    {
                                        transferType === "email" && permissionMode === false ?
                                            <div>
                                                <div className="formElement">
                                                    <div className={classes.editor}>
                                                        <RichTextEditor 
                                                            toolbarConfig={this.getConfig()} 
                                                            onChange={(content) => this.handleMessageChange(content, "message")}
                                                            value={message}
                                                            toolbarClassName="sd-editor-toolbar"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <></>
                                    }
                                </div>
                            </div>
                            <div className={classes.shareModalTransferFooter}>
                                {
                                    files.length <= 0 ?
                                        <Button color="white" type="button" disabled={true} round className={classes.fileSendingButton}>
                                            <img src={sendiconDisabled} alt={"share"} onClick={this.onDirectoryFocus} />
                                            {transferType === "email" ? "Share" : "Get a link" }
                                        </Button>
                                    :
                                        <Button color="white" onClick={this.sendFiles} type="button" round className={classes.fileSendingButton}>
                                            <img src={sendicon} alt={"share"} onClick={this.onDirectoryFocus} />
                                            {transferType === "email" ? "Share" : "Get a link" }
                                        </Button>
                                }
                                <div className={classes.newUserActions+" "+classes.notificationActions}>
                                    <div className={classes.ownerNotification}>
                                        <label>Notify Owner</label>
                                        <div className={classes.customSelectControl}>
                                            <CustomSelect 
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                labelText="Notify Owner"
                                                selectProps={{
                                                    onChange: (e) => this.onSelectControlChange(e, 'ownerNotification'),
                                                    value: ownerNotification
                                                }}
                                                inputProps={{
                                                    name: "ownerNotification",
                                                }}
                                                color="custom"
                                                items={this.notificationOptions}
                                                itemLabel="value"
                                                itemValue="key"
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.groupNotification}>
                                        <label>Notify Group</label>
                                        <div className={classes.customSelectControl}>
                                            <CustomSelect 
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                labelText="Notify Group"
                                                selectProps={{
                                                    onChange: (e) => this.onSelectControlChange(e, 'groupNotification'),
                                                    value: groupNotification
                                                }}
                                                inputProps={{
                                                    name: "groupNotification",
                                                }}
                                                color="custom"
                                                items={this.notificationOptions}
                                                itemLabel="value"
                                                itemValue="key"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.newUserActions}>
                                    <div className={classes.newExpiryDate}>
                                        <label>Expiry Date:</label>
                                        {this.getFormatedExpiry(expireNewUser)}
                                        <Button color="transparent" justIcon onClick={() => this.onSetExpiryDate(true)}>
                                            <MaterialIcon path={mdiPencil} className="MuiSvgIcon-root" />
                                        </Button>
                                    </div>
                                    <div className={classes.newVersionControl}>
                                        <label>Version Control:</label>
                                        <div className={classes.customSelectControl}>
                                            <CustomSelect 
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                labelText="Version Control"
                                                selectProps={{
                                                    onChange: (e) => this.onSelectControlChange(e, 'versionControl'),
                                                    value: versionControl
                                                }}
                                                inputProps={{
                                                    name: "versionControl",
                                                }}
                                                color="custom"
                                                items={this.versionControlOptions}
                                                itemLabel="value"
                                                itemValue="key"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                files.length > 0 ?
                                    <div className={classes.uploaderList+" uploader-list"}>
                                        <div className="uploader-add-files-count">
                                            <div className="files-permission-header">
                                                <span>
                                                    <MaterialIcon path={mdiPencil} title={"Read Write"} />
                                                </span>
                                                <span>
                                                    <MaterialIcon path={mdiEyeOutline} title={"View Only"} />
                                                </span>
                                            </div>
                                            <div className="files-detail">
                                                {filesCount} {filesCount > 1 ? "files" : "file"} added<span>{helper.getFormatedSize(remainingSize)} remaining</span>
                                            </div>
                                        </div>
                                        <ul className={classes.filesList}>
                                            {
                                                files.map((item, key) => (
                                                    this.renderFile(item, key)
                                                ))
                                            }
                                        </ul>
                                    </div>
                                :
                                    <></>
                            }
                        </div>
                    }
                    {
                        permissionMode && sharedWith !== null && sharedWith.length > 0 ?
                            <ReactTable
                                columns={this.getColumns()}
                                data={tableData}
                                pageSizeOptions={[500]}
                                defaultPageSize={50000000000}
                                showPaginationTop={false}
                                minRows={0}
                                showPaginationBottom={false}
                                className={"-striped -highlight "+classes.usersTable}
                                getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                getNoDataProps={() => this.getNoDataProps()}
                                sortable={false}
                                SubComponent={(row) => {
                                    return (
                                        <UserRecentActivity row={row} checkFileIds={this.getCheckedFileIds()} />
                                    )
                                }}
                            />
                        :
                        <></>
                    }
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        autoHideDuration={3000}
                        open={removedNotice}
                        onClose={() => this.onNoticeClose()}
                        message={<span>Email removed.</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.onNoticeClose()}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                    {
                        changeExpiryDateModal ?
                            <ChangeExpiryDateModal 
                                open={changeExpiryDateModal} 
                                saving={changingExpiryDate} 
                                onClose={() => this.onChangeExpiryDate(false)} 
                                onSuccess={(expire) => this.onChangeExpiryDateSuccess(expire)}
                                confirmationMessage="Are you sure you want to change the expiry?"
                                expire={expire}
                            />
                        :
                        <></>
                    }
                    {
                        setExpiryDateModal ?
                            <ChangeExpiryDateModal 
                                open={setExpiryDateModal} 
                                saving={changingExpiryDate} 
                                onClose={() => this.onSetExpiryDate(false)} 
                                onSuccess={(expire) => this.onSetExpiryDateSuccess(expire)}
                                confirmationMessage="Are you sure you want to set the expiry?"
                                expire={expire}
                            />
                        :
                        <></>
                    }
                    {
                        clearUsersModal ?
                            <ConfirmationModal 
                                open={clearUsersModal} 
                                saving={clearingUsers} 
                                onClose={() => this.onClearUsersModal(false)} 
                                onSuccess={() => this.onClearUsersModalSuccess()}
                                confirmationMessage="Are you sure you want to clear users?"
                            />
                        :
                        <></>
                    }
                    {
                        changeBulkExpiryDateModal ?
                            <ChangeExpiryDateModal 
                                open={changeBulkExpiryDateModal} 
                                saving={changingExpiryDate} 
                                onClose={() => this.onBulkExpiryDateChange(false)} 
                                onSuccess={(expire) => this.onBulkExpiryDateChangeSuccess(expire)}
                                confirmationMessage="Are you sure you want to set the expiry?"
                                expire={expire}
                            />
                        :
                        <></>
                    }
                    {
                        changeVersionControlModal ?
                            <ChangeVersionControlModal 
                                open={changeVersionControlModal} 
                                saving={changingExpiryDate} 
                                onClose={() => this.onBulkVersionControlChange(false)} 
                                onSuccess={(versionControl) => this.onBulkVersionControlChangeSuccess(versionControl)}
                                confirmationMessage="Are you sure you want to change version control?"
                                versionControl={versionControl}
                                store={this.store}
                            />
                        :
                        <></>
                    }
                    {
                        changeBulkOwnerNotiModal ?
                            <ChangeNotificationModal 
                                open={changeBulkOwnerNotiModal} 
                                onClose={() => this.onBulkNotiChange(false, 'changeBulkOwnerNotiModal')} 
                                onSuccess={(ownerNotification) => this.onBulkNotiChangeSuccess(ownerNotification, 'ownerNotification', 'changeBulkOwnerNotiModal')}
                                confirmationMessage="Are you sure you want to change notify owner?"
                                notification={ownerNotification}
                                labelText="Change Notify Owner"
                                store={this.store}
                            />
                        :
                        <></>
                    }
                    {
                        changeBulkGroupNotiModal ?
                            <ChangeNotificationModal 
                                open={changeBulkGroupNotiModal} 
                                onClose={() => this.onBulkNotiChange(false, 'changeBulkGroupNotiModal')} 
                                onSuccess={(groupNotification) => this.onBulkNotiChangeSuccess(groupNotification, 'groupNotification', 'changeBulkGroupNotiModal')}
                                confirmationMessage="Are you sure you want to change notify group?"
                                notification={groupNotification}
                                labelText="Change Notify Group"
                                store={this.store}
                            />
                        :
                        <></>
                    }
                    {
                        shareUserStatusModal ?
                            <ShareUserStatusModal
                                open={shareUserStatusModal} 
                                onClose={() => this.onShareUserStatusModal(false)} 
                                onSuccess={(status) => this.onShareUserStatusSuccess(status)}
                                confirmationMessage="Status"
                                status={shareUserStatus}
                            />
                        :
                            <></>
                    }
                    {
                        signupEmailModal ?
                            <ConfirmationModal 
                                open={signupEmailModal} 
                                onClose={() => this.onSignupEmailModalClose(false)} 
                                onSuccess={() => this.onSignupEmailModalSuccess()}
                                confirmationMessage={permissionUser.title+" will be sent an email to create a free limited access account to upload files to the folder."}
                            />
                        :
                        null
                    }
                </DialogContent>
                {
                    permissionMode && sharedWith !== null && sharedWith.length > 0 ?
                        <DialogActions className={classes.modalFooter +" " +classes.justifyContentBetween}>
                            <a target="_blank" rel="noopener noreferrer" href={apiUrl} className={classes.csvFileIcon}>
                                <img src={CsvFileIcon} alt={CsvFileIcon} /> Export All User's Activity
                            </a>
                            <div className={classes.actionButtons}>
                                <Button color="white" disabled={checkedUsers.length > 1 ? false : true} className={classes.changeButton} round onClick={() => this.onBulkNotiChange(true, 'changeBulkOwnerNotiModal')}>
                                    <img className="button-icon" src={notificationIcon} alt={"Change Notify Owner"} />
                                    Change Notify Owner
                                </Button>
                                <Button color="white" disabled={checkedUsers.length > 1 ? false : true} className={classes.changeButton} round onClick={() => this.onBulkNotiChange(true, 'changeBulkGroupNotiModal')}>
                                    <img className="button-icon" src={notificationIcon} alt={"Change Notify Group"} />
                                    Change Notify Group
                                </Button>
                                <Button color="white" disabled={checkedUsers.length > 1 ? false : true} className={classes.changeButton} round onClick={() => this.onBulkExpiryDateChange(true)}>
                                    <img className="button-icon" src={expiryChangeIcon} alt={"Change Expiry"} />
                                    Change Expiry
                                </Button>
                                <Button color="white" disabled={checkedUsers.length > 1 ? false : true} className={classes.changeButton} round onClick={() => this.onBulkVersionControlChange(true)}>
                                    <img className="button-icon" src={versionControlIconNew} alt={"Change Version Control"} />
                                    Change Version Control
                                </Button>
                                <Button color="white" disabled={checkedUsers.length >= 1 ? false : true} className={classes.changeButton} round onClick={() => this.onClearUsersModal(true)}>
                                    <img className="button-icon" src={clearUserIcon} alt={"Clear Users"} />
                                    Remove Access
                                </Button>
                            </div>
                        </DialogActions>
                    :
                        <></>
                }
            </Dialog>
        )
    }
}

ShareModal.defaultProps = {
    open: false,
    saving: false,
    fileId: null,
    sharedWith: null,
    sharedPage: false
}
ShareModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    saving: PropTypes.bool,
    sharedPage: PropTypes.bool
}
export default withStyles(shareModalStyle)(ShareModal);