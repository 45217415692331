import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';
import GridItem from "../../components/Grid/GridItem.jsx";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";

import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import CustomInput from "../CustomInput/CustomInput.jsx";

import disconnectAccountModalStyle from "../../../assets/jss/user/disconnectAccountModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const DisconnectAccountModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { disconnectAccountDetail } = this.props;

        this.state = {
            disconnectAccountDetail: disconnectAccountDetail,
            password: "",
            passwordConfirm: "",
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                password: "",
                passwordConfirm: "",
                isValid: false,
            },
        };

        this.accountTypes = Api.getPlans();
        this.disconnectAccount = this.disconnectAccount.bind(this);
    }
    disconnectAccount(){
        const { disconnectAccountDetail, password } = this.state;
        
        const isValid = this.validateForm();
        if(!isValid){
            return false;
        }

        const requestData = {
            id: disconnectAccountDetail.userId,
            password: password
        }

        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });
        
        const that = this;
        Api.disconnectAccount(requestData, source).then(data => {
            that.setState({
                success: true,
                saving: false
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message,
                    cancelToken: null,
                });
            }
        });
    }
    validateForm(){
        let validation = {
            password: "success",
            passwordConfirm: "success",
            isValid: true
        };
        
        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        
        if(this.state.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    renderForm(){
        const { saving, validation, password, passwordConfirm, showPasswordErrors, requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength } = this.state;
        const { classes } = this.props;
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.editPackage}>
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                            success={validation.password === "success"}
                            error={validation.password === "error"}
                            id="input-password"                                        
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'password'),
                                name: "password",
                                type: "password",
                                value: password,
                                placeholder:"Password"
                            }}
                        />
                        {
                            showPasswordErrors ?
                                <GridItem>
                                    <div>
                                        <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                        </div>
                                        <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                        </div>
                                        <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-symbols">Password must contain a number</span>
                                        </div>
                                        <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                        </div>
                                        <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                        </div>
                                    </div>
                                </GridItem>
                            :
                            <></>
                        }
                        <CustomInput
                            success={validation.passwordConfirm === "success"}
                            error={validation.passwordConfirm === "error"}
                            id="input-signupPasswordConfirm"
                            inputProps={{
                                required: true,
                                onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                name: "passwordConfirm",
                                type: "password",
                                value: passwordConfirm,
                                placeholder:"Type Password Again",
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                            }}
                        />
                    </div>
                    <div className={classes.modalFooter}>
                        <Button color="white" onClick={this.disconnectAccount} type="button">
                            {
                                saving ?
                                    <LoaderComponent color="white" align="left" saving={true} />
                                :
                                    "Save Changes"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    render() {
        const { success, showError } = this.state;
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="editPackage-slide-title"
                aria-describedby="editPackage-slide-description"
            >
                <DialogTitle
                    id="editPackage-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h5 className={classes.modalTitle}>Disconnect Account</h5>
                </DialogTitle>
                <DialogContent
                    id="editPackage-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            success ?
                                <>
                                    <p className={classes.successMessage}>Disconnected successfully.</p>
                                    <Button color="white" onClick={() => this.props.onSuccess()}>
                                        Close
                                    </Button>
                                </>
                            :
                                <>
                                    {
                                        showError ?
                                            <div className={classes.errorsSections}>
                                                { this.renderErrorMessages() }
                                            </div>
                                        :
                                        <></>
                                    }
                                    { this.renderForm() }
                                </>
                        }
                </DialogContent>      
            </Dialog>
        );
    }
};

DisconnectAccountModal.defaultProps = {
    open: false,
};
DisconnectAccountModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    disconnectAccountDetail: PropTypes.object.isRequired,
};
export default withStyles(disconnectAccountModalStyle)(DisconnectAccountModal);
