import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader";
import { helper } from '../../../assets/js/utils/Element';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "../CustomInput/CustomInput";
import sendlinxIcon from "../../../assets/img/sendlinx-icon.png";
import facebookIcon from "../../../assets/img/facebook-icon.png";
import twitterIcon from "../../../assets/img/twitter-icon.png";
import linkedinIcon from "../../../assets/img/linkedin-icon.png";
import emailIcon from "../../../assets/img/email-icon.png";
import generateQrIcon from "../../../assets/img/generate-qr-icon.png";
import externalLinkModalStyle from "../../../assets/jss/user/externalLinkModalStyle.jsx";
import MaterialIcon from "@mdi/react";
import { mdiLink } from '@mdi/js';
import Link from "@material-ui/core/Link";
import QRCode from 'qrcode.react';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateTimePicker from "../CustomInput/DateTimePicker";
import InputAdornment from "@material-ui/core/InputAdornment";
import Check from "@material-ui/icons/Check";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Close from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ExternalLinkModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        const { linkDetail, editMode } = this.props;

        let readOnlyUrl = false;
        if(linkDetail.url !== '' && linkDetail.title === ''){
            readOnlyUrl = true;
        }
        this.state = {
            orgLinkDetail: linkDetail,
            linkDetail: linkDetail,
            snackbarOpen: false,
            url: linkDetail.url,
            title: linkDetail.title,
            linkPassword: linkDetail.linkPassword,
            hideLink: linkDetail.hideLink,
            expire: linkDetail.expire,
            modifiedFields: {
                url: false
            },
            editMode: editMode,
            readOnlyUrl: readOnlyUrl,
            viewPassword: false
        };

        this.copyExternalLink = this.copyExternalLink.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }
    copyExternalLink(){
        let { externalLink } = this.props;
        externalLink = window.location.protocol+"//"+window.location.host+externalLink;
        helper.copyTextToClipboard(externalLink);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    onClearDate(){
        this.setState({
            expire: ''
        })
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    handleChange(e, name) {
        this.setState({
            [name]: e.target.value,
            modifiedFields: {
                ...this.state.modifiedFields,
                [name]: true
            }
        });
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    downloadQRCode(){
        const canvas = document.getElementById("qr-code");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let externalLink = document.createElement("a");
        externalLink.href = pngUrl;
        externalLink.download = "qr-code.png";
        document.body.appendChild(externalLink);
        externalLink.click();
        document.body.removeChild(externalLink);
    }
    onLinkName(){
        const { url, title, linkPassword, hideLink, modifiedFields, editMode, linkDetail, expire } = this.state;
        const isValidUrl = this.isValidUrl();
        if(url.length <= 0 || !isValidUrl){
            this.setState({
                modifiedFields: {
                    ...modifiedFields,
                    url: true
                }
            });
            return;
        }

        let linkData = {
            url: url,
            name: title,
            linkPassword: linkPassword,
            hideLink: hideLink,
            expire: expire
        };
        if(linkDetail.hasOwnProperty("hash")){
            linkData['hash'] = linkDetail.hash;
        }
        this.props.onLinkName(linkData, editMode);
    }
    isValidUrl(){
        const { url, modifiedFields } = this.state;
        if(url.length <= 0 && !modifiedFields.url){
            return true;
        }
        try{
            if(typeof(URL) === "function"){
                new URL(url);
                return true;
            }
        }catch(e){ return false; }

        return helper.isURL(url);
    }
    onViewPassword(){
        this.setState({
            viewPassword: !this.state.viewPassword
        })
    }
    saveButtonDisabled(){
        const { orgLinkDetail, url, title, linkPassword, hideLink, expire } = this.state;

        if(
            (orgLinkDetail.url !== url) ||
            (orgLinkDetail.title !== title) ||
            (orgLinkDetail.linkPassword !== linkPassword) ||
            (orgLinkDetail.hideLink !== hideLink) ||
            (orgLinkDetail.expire !== expire)
        ){
            return false;
        }

        return true;
    }
    renderErrorMessages(){
        const { errorMessage, classes } = this.props;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={classes.errorMessageDiv+" passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={classes.errorMessageDiv+" passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    render() {
        const { classes, saving, loadingMessage, successMessage, errorMessage, externalLink, linkName } = this.props;
        const { url, title, linkPassword, modifiedFields, hideLink, editMode, readOnlyUrl, expire, viewPassword } = this.state;
        let externalLinkUrl = window.location.protocol+"//"+window.location.host+externalLink;
        const isValidUrl = this.isValidUrl();
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="externalLinkModal-modal-slide-title"
                aria-describedby="externalLinkModal-modal-slide-description"
            >
                <DialogTitle
                    id="externalLinkModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    {
                        saving === false && linkName ?
                            <Button
                                simple
                                className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.onClose()}
                            >
                                {" "}
                                <CloseIcon className={classes.modalClose} />
                            </Button>
                        :
                            <></>
                    }
                    {
                        saving ?
                            <h4 className={classes.modalTitle+" "+classes.textCenter}>{loadingMessage}</h4> 
                        :
                            externalLink === null ?
                                <>
                                    {
                                        editMode ?
                                            <h5 className={classes.modalTitle}>Edit External Link</h5>
                                        :
                                            <h5 className={classes.modalTitle}>Add External Link</h5>
                                    }
                                </>
                            :
                                <></>
                    }
                </DialogTitle>
                <DialogContent
                    id="externalLinkModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            errorMessage !== null?
                                this.renderErrorMessages()
                            :
                                <></>
                        }
                        {
                            saving ?
                                <LoaderComponent color="custom" align="center" />
                            : linkName ?
                                <>
                                    <CustomInput
                                        labelText="Source Path"
                                        id="input-linkUrl"
                                        success={(url.length > 0 && isValidUrl ? true : false)}
                                        error={(((modifiedFields.url && url.length <= 0) || isValidUrl === false) ? true : false)}
                                        inputProps={{
                                            value: url,
                                            name: "linkUrl",
                                            type: "text",
                                            onChange: (e) => this.handleChange(e, 'url'),
                                            disabled: readOnlyUrl,
                                        }}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Link Name (optional)"
                                        id="input-linkTitle"
                                        inputProps={{
                                            value: title,
                                            name: "linkTitle",
                                            type: "text",
                                            onChange: (e) => this.handleChange(e, 'title')
                                        }}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                    <div className={classes.dateTimePicker}>
                                        <DateTimePicker
                                            labelText="Expiry Date (optional)"
                                            id="input-expire"
                                            value={expire}
                                            onChange={(date) => this.handleDateChange(date, 'expire')}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            disablePast={true}
                                        />
                                        {
                                            expire && expire !== '' ?
                                                <Button className="clear-icon" justIcon color="custom" onClick={() => this.onClearDate()}>
                                                    <Close className={classes.inputAdornmentIcon}/>
                                                </Button>
                                            :
                                                <></>
                                        }
                                    </div>
                                    <CustomInput
                                        labelText="Link Password (optional)"
                                        id="input-password"
                                        inputProps={{
                                            value: linkPassword,
                                            name: "linkPassword",
                                            type: viewPassword ? "text" : "password",
                                            onChange: (e) => this.handleChange(e, 'linkPassword'),
                                            endAdornment: (
                                                <InputAdornment 
                                                    className={classes.inputAdornment+' viewPasswordIcon'} 
                                                    onClick={() => this.onViewPassword() }
                                                    position='end'
                                                >
                                                    {
                                                        linkPassword ?
                                                            viewPassword ?
                                                                <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                            :
                                                                <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                        :
                                                            <></>
                                                    }
                                                </InputAdornment>
                                            ),
                                        }}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Checkbox                                                
                                                tabIndex={-1}
                                                onClick={(e) => this.handleCheckbox(e, "hideLink")}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{checked: classes.checked, root: classes.checkRoot}}
                                                checked={hideLink}
                                            />
                                        }
                                        label={("Recommended only for sites that allow embedding or direct file downloads.")}
                                    />
                                </>
                            :
                                <>
                                    <h4>{successMessage}</h4>
                                    {
                                        externalLink !== null ?
                                            <>
                                                <div className={classes.downloadLink}>
                                                    <p>Share your link:</p>
                                                    <div className={classes.dNone}>
                                                        <QRCode value={externalLinkUrl} id='qr-code' />
                                                    </div>
                                                    <ul className='share-icon-lists'>
                                                        <li className='sendlinx-icon'>
                                                            <a href='/' onClick={e => e.preventDefault()}>
                                                                <img src={sendlinxIcon} alt="sendlinx" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={'https://www.facebook.com/sharer/sharer.php?u='+externalLinkUrl} target="_blank" rel="noopener noreferrer">
                                                                <img src={facebookIcon} alt="facebook" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={'https://twitter.com/intent/tweet?url='+externalLinkUrl} target="_blank" rel="noopener noreferrer">
                                                                <img src={twitterIcon} alt="twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={'https://www.linkedin.com/shareArticle?mini=true&url='+externalLinkUrl} target="_blank" rel="noopener noreferrer">
                                                                <img src={linkedinIcon} alt="linkedin" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={'mailto:?&subject=&cc=&bcc=&body='+externalLinkUrl} target="_blank" rel="noopener noreferrer">
                                                                <img src={emailIcon} alt="email" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <Link onClick={this.downloadQRCode}>
                                                                <img src={generateQrIcon} alt='generate qr code' />
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                    <div className='download-link-section'>
                                                        <div className='icon'>
                                                            <MaterialIcon path={mdiLink} className="MuiSvgIcon-root" />    
                                                        </div>
                                                        <p>{externalLinkUrl}</p>
                                                        <Button color="custom" size='sm' type="button" round onClick={this.copyExternalLink} >Copy Link</Button>
                                                    </div>
                                                </div>
                                            </>
                                        :
                                        <></>
                                    }
                                </>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter +" " +classes.justifyContentCenter}>
                    {
                        saving === false ?
                            <>
                                {
                                    linkName ?
                                        <Button color="custom" onClick={() => this.onLinkName()} round disabled={editMode && this.saveButtonDisabled()}>
                                            { editMode ? "Change" : "Continue" }
                                        </Button>
                                    :
                                        <>
                                            <Button color="custom" onClick={() => this.props.onSuccess()} round>
                                                OK
                                            </Button>
                                        </>
                                }
                            </>
                        :
                            <></>
                    }
                </DialogActions>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.snackbarOpen}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Your link has been copied</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.closeSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
            </Dialog>
        )
    }
}

ExternalLinkModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false,
    errorMessage: null,
    externalLink: null,
    linkName: false,
    onLinkName: function(){ },
    editMode: false,
}
ExternalLinkModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool,
    errorMessage: PropTypes.object,
    externalLink: PropTypes.string,
    linkName: PropTypes.bool,
    onLinkName: PropTypes.func,
    editMode: PropTypes.bool
}
export default withStyles(externalLinkModalStyle)(ExternalLinkModal);