import {
    whiteColor,
    grayColor,
    infoColor,
    controls,
    controlButtons,
    sidebar,
    tableHeadColumns,
    separator,
    content
} from "../material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const userTrashPageStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    controls: {
        ...controls(theme),
    },
    controlButtons: {
        ...controlButtons(theme),
    },
    sidebar: {
        ...sidebar(theme)
    },
    main: {
        marginTop: "0px"
    },
    container: {
        width: "100%",
        zIndex: "2",
        top: "62px",
        position: "relative",
        paddingTop: "20px",
        height: "calc(100vh - 62px)"
    },
    content: {
        ...content(theme)
    },
    files: {
        paddingLeft: "10px",
        maxHeight: "500px",
        minHeight: "500px",
        overflow: "auto",
        maxWidth: "99%",
        width: "100%"
    },
    filesTable: {
        ...tableHeadColumns(theme),
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
            color: theme.customize.color,
        },
        "& .rt-tbody": {
            overflow: "unset",
            "& .rt-td": {
                padding: "0px 5px",
                margin: "5px 0px",
                "&.td_attachments": {
                    wordBreak: "break-all",
                    "& a+a": {
                        "&:before": {
                            content: "','",
                            display: "inline-block",
                            marginLeft: "5px",
                            marginRight: "5px",
                        }
                    },
                    "& button": {
                        width: "auto !important",
                        height: "auto !important",
                        textTransform: "none",
                        "&:hover": {
                            textDecoration: "underline",
                        }
                    }
                },
                "& button": {
                    margin: "0px",
                    padding: "0px",
                    minWidth: "0px",
                    marginRight: "5px"
                },
                "&.td_hide": {
                    display: "none"
                }
            },
        },
        "& .hd_check": {
            width: "30px !important",
            flex: "none !important",
            textAlign: "center !important",
            marginRight: "10px !important",
            "& .MuiCheckbox-root": {
                paddingLeft: "0px",
                paddingRight: "0px",
            }
        },
        "& .hd_name": {
            width: "45% !important",
            textAlign: "left !important",
        },
        "& .hd_attachments": {
            width: "15% !important",
            textAlign: "left !important",
            "& button": {
                textTransform: "none",
                "&:hover": {
                    textDecoration: "underline"
                }
            }
        },
        "& .hd_type": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_path": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_size": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_creation_date": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_owner": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .td_check": {
            "& .MuiButtonBase-root": {
                padding: "0px",
            }            
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color: grayColor[17],
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px"
        },
        "& .rt-tr": {
            cursor: "pointer"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px"
        }
    },
    uncheckedIcon: {
        ...customCheckboxRadioSwitchStyle.uncheckedIcon,
        borderColor: theme.customize.checkboxColor,
    },
    checkedIcon: {
        ...customCheckboxRadioSwitchStyle.checkedIcon,
        borderColor: "#2171C6",
        background: "#2171C6",
        fill: whiteColor
    },
    fileIcon: {
        height: "auto",
        width: "auto",
        padding: "0px 3px",
        minWidth: "0px",
        margin: "0px",
        marginRight: "5px",
        "& img": {
            width: "24px"
        },
        "& .star_filled": {
            color: theme.customize.sidebarMenuIconsColor
        }
    },
    loadMore: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        color: whiteColor,
        marginBottom: "20px",
        width: "100%",
        textShadow: "2px 2px 2px #000",
        marginTop: "0px"
    },
    breadcrumbs: {
        padding: "5px 30px",
        textAlign: "left",
        "& a": {
            color: infoColor[0]+" !important",
            verticalAlign: "middle",
        },
        "& div": {
            display: "inline-block",
            verticalAlign: "middle",
        },
        "& span": {
            margin: "0px 5px",
            verticalAlign: "middle",
            display: "inline-block",
            height: "18px",
            "& svg": {
                width: "18px",
                height: "18px"
            }
        },
        "& img": {
            width: "18px",
            marginRight: "5px"
        }
    },
    separator: {
        ...separator(theme)
    },
    loading: {
        display: "none"
    }
});

export default userTrashPageStyle;
