import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import connectModalStyle from "../../../assets/jss/docusign/connectModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ConnectModal = class extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="docusignConnectModal-slide-title"
                aria-describedby="docusignConnectModal-slide-description"
            >
                <DialogContent
                id="docusignConnectModal-slide-description"
                className={classes.modalBody}
                >
                    <p>Ready to sign, send and manage documents from Sendlinx?</p>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.justifyContentCenter}
                >
                    <Button color="white" onClick={() => this.props.onClose()} round>Cancel</Button>
                    <Button color="custom" onClick={() => this.props.onSuccess("login")} round>Log In</Button>
                    <Button color="custom" onClick={() => this.props.onSuccess("signup")} round>Sign Up</Button>
                </DialogActions>        
            </Dialog>
        );
    }
};

ConnectModal.defaultProps = {
    open: false,
    deletingUser: false,
    deleteAll: false,
    saving: false
};
ConnectModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    deleteAll: PropTypes.bool,
    saving: PropTypes.bool
};
export default withStyles(connectModalStyle)(ConnectModal);
